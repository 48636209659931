import { Text } from 'slate';
import React, { useContext } from 'react';
import { escape, forEach, has, isEqual, omit } from 'lodash';
import { H1, H2, H3, P, A, OL, UL, Title, RichTextContainer, Li, ULChecked, checkedList } from './styledComponents';
import { LIST_TYPES as ALL_LIST_TYPES } from './plugins/withMultiLevelList';

export const isRichTextString = (str) => str?.startsWith?.('[{"type"');

export const PlaceholderContext = React.createContext();
export const PlaceholderContextProvider = PlaceholderContext.Provider;
export const usePlaceholderContext = () => useContext(PlaceholderContext);

export const MentionElement = (props) => {
  const context = usePlaceholderContext();
  const str = String(context?.[props?.name] || '');
  if (isRichTextString(str)) {
    // eslint-disable-next-line no-use-before-define
    return renderRichText(str);
  }
  const richObject = { text: str, bold: props.bold, italic: props.italic, underline: props.underline };
  // eslint-disable-next-line no-use-before-define
  return renderRichText(richObject);
};

export const richEditorTextStyles = ({ theme, isEmail }) => `<style>${checkedList({ theme, isEmail, prefix: 'ul.checked-list' })}</style>`;

export const richEditorTextToText = (rt, placeholderObject = {}, { newline, addHtmlTags, escape: doEscape, isEmail, checkedIconSrc } = {}) => {
  const f = (inputRT, props = {}) => {
    let text = '';
    if (Array.isArray(inputRT)) {
      text += inputRT.map((i) => f(i, props)).join('');
    } else if (inputRT?.type === 'mention') {
      text += richEditorTextToText(placeholderObject[inputRT.name], placeholderObject);
    } else if (has(inputRT, 'text')) {
      text += doEscape ? escape(inputRT.text) : inputRT.text;
    } else if (has(inputRT, 'children')) {
      if (inputRT.type === 'div' || inputRT.type === 'paragraph') text += newline ?? '\n';
      const parentList = ALL_LIST_TYPES.includes(inputRT.type) ? inputRT : props.parentList;
      text += f(inputRT.children, { parentList });
    } else text += inputRT;
    if (addHtmlTags) {
      if (inputRT?.italic) text = `<i>${text}</i>`;
      if (inputRT?.bold) text = `<b>${text}</b>`;
      if (inputRT?.underline) text = `<u>${text}</u>`;
      if (inputRT?.type === 'numbered-list') text = `<ol type="${inputRT?.listType || ''}">${text}</ol>`;
      if (inputRT?.type === 'bulleted-list') text = `<ul type="${inputRT?.listType || ''}">${text}</ul>`;
      if (inputRT?.type === 'checked-list') text = `<ul class="checked-list" type="none">${text}</ul>`;
      if (inputRT?.type === 'list-item') {
        let newText;
        if (isEmail && props.parentList?.type === 'checked-list') newText = `<div><img src="${checkedIconSrc}" alt="✔" title="✔" height="16px" width="16px" /></div><div>${text}</div>`;
        else newText = text;
        text = `<li>${newText}</li>`;
      }
    }
    return text;
  };
  let newRt = rt;
  if (newRt?.startsWith?.('[{"type')) newRt = JSON.parse(newRt);
  let text = f(newRt);
  if (text.startsWith('\n')) text = text.replace('\n', '');
  return text;
};

const LIST_TYPES = ['numbered-list', 'bulleted-list'];
// https://docs.slatejs.org/concepts/09-serializing
// https://github.com/ianstormtaylor/slate/issues/3246
// https://github.com/ianstormtaylor/slate/issues/3899
export const renderRichText = (node, isRootCall = true, index = undefined) => {
  if (!node) return null;
  if (isRootCall) return <RichTextContainer className="rich-text-rendered">{renderRichText(node, false)}</RichTextContainer>;
  if (isRichTextString(node)) node = JSON.parse(node); // eslint-disable-line no-param-reassign
  if (Array.isArray(node)) return renderRichText({ children: node }, false, index);
  const key = {
    key: index,
  };
  if (Text.isText(node)) {
    let component = <span {...key}>{node.text ? `${node.text}`.replace(/\n$/, '\n\uFEFF') /* space after \n in node text makes new line visible */ : '\uFEFF'}</span>;
    if (node.italic) component = <i {...key}>{component}</i>;
    if (node.bold) component = <b {...key}>{component}</b>;
    if (node.underline) component = <ins {...key}>{component}</ins>;
    return component;
  }

  if (node?.type === 'html') {
    const { html } = node;
    return <span dangerouslySetInnerHTML={{ __html: html }}></span>;
  }

  const children = node?.children?.map?.((n, listIndex) => renderRichText(n, false, listIndex));
  let component;
  const style = {};
  if (node?.align) style.textAlign = node.align;
  const attr = { style, ...key };
  if (node.listType && LIST_TYPES.includes(node.type)) attr.type = node.listType;
  switch (node?.type) {
    case 'div':
      return <div {...attr}>{children}</div>;
    case 'paragraph':
      return <P {...attr}>{children}</P>;
    case 'bulleted-list':
      return <UL {...attr}>{children}</UL>;
    case 'checked-list':
      return <ULChecked {...attr}>{children}</ULChecked>;
    case 'list-item':
      return <Li {...attr}>{children}</Li>;
    case 'numbered-list':
      return <OL {...attr}>{children}</OL>;
    case 'heading-one':
      return <H1 {...attr}>{children}</H1>;
    case 'heading-two':
      return <H2 {...attr}>{children}</H2>;
    case 'heading-three':
      return <H3 {...attr}>{children}</H3>;
    case 'title':
      if (!attr.style.textAlign) attr.style.textAlign = 'left';
      return <Title {...attr}>{children}</Title>;
    case 'mention':
      return <MentionElement {...node} />;
    case 'link':
      return (
        <A href={node.url} target="_blank" rel="noopener noreferrer">
          {children}
        </A>
      );
    default:
      component = children;
  }
  return component;
};

export const isRichTextEmpty = (node) => {
  // check rendered react text
  if (node?.props?.children?.length === 1 && node?.props?.children?.[0]?.props?.children?.length === 1 && (node?.props?.children?.[0]?.props?.children?.[0]?.props?.children === '' || encodeURIComponent(node?.props?.children?.[0]?.props?.children?.[0]?.props?.children) === '%EF%BB%BF')) return true;
  if (node?.props?.children?.length) return false;

  let isEmpty = true;
  try {
    try {
      if (isRichTextString(node)) node = JSON.parse(node); // eslint-disable-line no-param-reassign
    } catch (e) {} // eslint-disable-line no-empty
    // eslint-disable-next-line no-shadow
    forEach(node, (node) => {
      const isText = node?.children?.length > 1 || node?.children?.[0]?.text?.trim?.();
      const isList = node?.children?.length > 1 || node?.children?.[0]?.children?.length > 1 || node?.children?.[0]?.children?.[0]?.text?.trim?.();
      if (isText || isList) isEmpty = false;
    });
  } catch (e) {
    console.log(e);
  }
  return isEmpty;
};

export const merge = (target, ...sources) => {
  if (!sources.length) return target;
  const targetJSON = JSON.parse(target);
  const isEqualElements = (el1, el2) => isEqual(omit(el1, ['text', 'children']), omit(el2, ['text', 'children']));
  sources.forEach((source) => {
    const position = targetJSON.length - 1;
    const sourceJSON = JSON.parse(source);

    if (targetJSON[position]?.children) {
      const firstItem = sourceJSON[0];
      // merge paragraphs if the same styles are applied to them
      if (firstItem && isEqualElements(firstItem, targetJSON[position])) {
        sourceJSON.shift();
        const targetChildrenPosition = targetJSON[position].children.length - 1;
        const targetElement = targetJSON[position].children?.[targetChildrenPosition];
        const sourceElement = firstItem.children[0];
        const isLastTargetChildren = 'children' in (targetJSON[position].children?.[targetChildrenPosition] || {});
        const isFirstSecondChildren = 'children' in (sourceElement || {});
        // add space between paragraphs
        if (!isLastTargetChildren && isEqualElements(targetElement, {})) {
          // do not add space if the last target paragraph is empty
          if (!(!targetElement.text && !targetChildrenPosition)) targetElement.text += ' ';
        } else if (!isFirstSecondChildren && isEqualElements(sourceElement, {})) sourceElement.text = ` ${sourceElement.text}`;
        else if (!(!targetElement.text && !targetChildrenPosition)) targetJSON[position].children.push({ text: ' ' });

        // merge text elements if the last target element of the last paragraph and the first source element of the first paragraph are text elements
        if (!isLastTargetChildren && !isFirstSecondChildren && isEqualElements(targetElement, sourceElement)) {
          const firstChild = firstItem.children.shift();
          targetElement.text += firstChild.text;
        }
        // merge last and first element of target and source children
        targetJSON[position].children.push(...firstItem.children);
      }
      // merge target and source
      targetJSON.push(...sourceJSON);
    }
  });

  return JSON.stringify(targetJSON);
};

export const getDefaultRichEditorValue = ({ rootElement = 'div', defaultValue = '', toJsObject } = {}) => {
  const value = [{ type: rootElement, children: [{ text: `${defaultValue}` }] }];
  if (toJsObject) return value;
  return JSON.stringify(value);
};
